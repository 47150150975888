
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#8684a4; 							// the brand colour
$color-primary-light:	lighten($color-primary, 5%);		// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);			// the brand colour - darker
$color-primary-lighter:	lighten($color-primary, 15%);		// the brand colour - lighter

$color-secondary: 		#172154; 							// the accent colour
$color-secondary-light:	lighten($color-secondary, 5%);		// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);		// the accent colour - darker

$color-tertiary:		#edf100;							// the additional colour
$color-tertiary-light:	lighten($color-tertiary, 5%);		// the additional colour - lighter
$color-tertiary-dark:	darken($color-tertiary, 5%);		// the additional colour - darker


//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eee;								// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);		// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);		// a default light grey - darker

$color-midgrey:			#bbb;	 							// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);		// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);			// a default mid grey - darker

$color-darkgrey:		#85898a;							// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);		// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);		// a default dark grey - darker

$color-white: 			#fff;								// a default white
$color-black: 			#111;								// a default black
$seach-icon:			#646567;							// search icon color
$color-nav-bg:			$color-secondary;					// nav background
$color-footer:			#cecdd7;							// footer colour
$phone-color:			$color-secondary;					// phone colour
$color-error:			#e74c3c;							// error colour

//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#717f81;									// body text colour
$color-sub:		#5d4f4a;									// sub and intro text colour
$color-title:	$color-primary;								// heading text colour

