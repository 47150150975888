
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: 0.5rem 3rem;
    border-radius: $border-radius;
    transition: $transition;
    font-weight: $font-bold + 100;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
