
// Clear paragraph styles
p {
	margin-bottom: $block-margin - 2rem;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags 
hr {
	border: $border;
}

// asterisk styling
.asterisk {
	color: $color-primary;
	font-weight: $font-bold;
}
