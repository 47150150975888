
//==========  Borders  ==========//

// build our default border style 

$border-size: 1px;
$border-style: solid;
$border-color: $color-lightgrey;

$footer-color-border: #e2e1e7;
$cta-block-color: #cfcedb;
$nav-border-color: #9998b3;
$hr-border-style: rgba(172, 169, 210, 0.24);
// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 4px;

$footer-block-border: $border-size $border-style $footer-color-border;
$cta-block-border: $border-size $border-style $cta-block-color;
$nav-border: $border-size $border-style $nav-border-color;
$hr-border: $border-size $border-style $hr-border-style;
